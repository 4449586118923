<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Berita </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputNews">
            <CRow>
              <CCol sm="8">
                <div class="form-group">
                  <label style='clear:both;'>
                    
                    <CInputCheckbox
                      label="Schedule"
                      value="1"
                      @change="checkSchedule(1, $event)" 
                      v-model="isSchedule" 
                    /> </label><br>
                  <date-picker v-model="tgl_berita" v-if="showDate" readonly value-type='timestamp' class='w-50' type="datetime"></date-picker>
                </div>
                <CInput
                  v-model="title"
                  label="Judul Berita"
                  required="true"
                  placeholder="Input Judul Berita"
                />
                <div class="form-group">
                  <label>Berita</label>
                  <!-- <vue-editor v-model="content"       
                  @focus="onEditorFocus" 
                  :editorOptions="editorSettings2"
                  ref="content"
                  ></vue-editor> -->
                  <editor
                    v-model="details"
                    :api-key="apiKey"
                    :init="editorSettings"
                    @onInit="handleEditorInit"
                  />
                </div>
                <!-- <div class="form-group">
                  <label>Berita Terkait</label>
                  <autocomplete
                      ref="autocomplete"
                      url="http://139.180.219.157/kasir/api/index"
                      anchor="product_name"
                      :classes="{ wrapper: 'form-wrapper', input: 'form-control', list: 'data-list', item: 'data-list-item' }"                    
                      param="q"
                      id="autocom"
                      label="writer"
                      :min="3"
                      :process = "processJSON"
                      :onSelect="getData">
                    </autocomplete>
                  </div> -->
              </CCol>
              <CCol sm="4">  
                <div class="form-group">
                  <img v-if="image_link.length>0" :src="image_link" class="img-thumbnail mb-3" />
                  <label>Image</label>              
                  <div class="input-group mb-3">
                    <input type="text" 
                  required="true" class="form-control" placeholder="Browse Image" @click="galeriModal = true; imageSourceClick = 'button'; initLoadImage();" v-model="image_link"  readonly />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-light" @click="galeriModal = true; imageSourceClick = 'button'; initLoadImage();"><CIcon name="cil-image" width="18" /></button>
                    </div>
                  </div>
                  <CInput
                    v-model="caption_image"
                    type="text"
                    required="true"
                    label="Caption Image"
                    placeholder="Input Caption Image"
                  />
                </div>
                <div class="form-group">
                  <label>Rubrik</label>
                  <select v-model="category" class="form-control"  required="true">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="o in categories" :value="o.id" :key="o.id">{{o.name}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Deskripsi</label>
                  <textarea v-model="deskripsi" class="form-control"></textarea>
                </div>
                <div class="form-group">
                  <label>Tag</label>
                  <tags-input 
                    element-id="tags"
                    v-model="source"
                    :existing-tags="tags2"
                    :add-tags-on-comma="true"
                    typeahead-style="dropdown"
                    :typeahead="true"></tags-input>

                </div>
                <CInput
                  v-model="author"
                  type="text"
                  label="Author"
                  placeholder="Input Author"
                />
                <div class="form-group">
                  <label>Slider</label>
                  <select v-model="slider" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="0" selected>Tidak</option>
                    <option value="1" >Ya</option>
                  </select>
                </div>
                
              </CCol>
            </CRow>          
              <div class="form-actions float-left">
                <CButton type="submit" color="primary" :disabled="!isSubmitable" style='margin-right:15px'>{{ submitString }}</CButton>
                <CButton color="secondary" to="/news">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>

    
    <CModal
      title="Modal title"
      size="xl"
      :show.sync="galeriModal"
    >
      <CInput
        class="mb-3"
        v-model="search"
        placeholder="Search Image"
      >
        <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
        <template #append>
          <CButton color="info" 
        @click="searchImage()">Search</CButton>
        </template>
      </CInput>
      <div class="row">
        <div class="col-md-3">
          <div class="card" style="height:295px;overflow:hidden" >
            <div class="card-img-top bg-light" style='height:148px;overflow:hidden' @click="setTinyContentNew()">
              <slot v-if="loadingUpload">
                <div class="loader"></div>
              </slot>
              <slot v-else>
                <input type='hidden' v-model="new_image" />
                <img :src="new_image_src" alt="new image" class="text-center" style='width:100%;margin: auto;display: block;' />
              </slot>
              
            </div>
            <div class="card-body" style="overflow: hidden;"   @click="setTinyContentNew()">
              <p class="card-text" ref="new_image_caption"  contenteditable="true">Ilustrasi</p>
            </div>
            <div>
              <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                  class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
              
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div>
                    Drop files anywhere to upload 
                    or <span class="underline">click here</span> to upload
                  </div>
                </label>
                <!-- <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                  <li class="text-sm p-1" v-for="file in filelist">
                    ${ file.name }<button class="ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">remove</button>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-6 " v-for="i in images" :key="i.id" >
          <div class="card" style="height:295px;overflow:hidden" @click="setTinyContent(i)">
            <img  :src="i.image" :alt="i.image_caption" class="card-img-top" />
            <div class="card-body">
              <p class="card-text">{{ i.image_caption }}</p>
            </div>
          </div>
        </div>
      </div>
      <template #header>
        <h6 class="modal-title">Galeri Foto</h6>
        <CButtonClose @click="galeriModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="galeriModal = false" >Cancel</CButton>
        <CButton @click="galeriModal = false" color="success">Save</CButton>
      </template>
    </CModal>
   
  </div>
</template>
<style scoped>
[v-cloak] {
  display: none;
}
.loader {
  margin: 30px auto;
  border: 10px solid #EAF0F6;
  border-radius: 50%;
  border-top: 10px solid #FF7A59;
  width: 70px;
  height: 70px;
  animation: spinner 4s linear infinite;
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
input.my-custom-class {
  display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
  .vue2-autocomplete-input-tag-items {
    border: 1px solid #768192;
    max-height: 200px;
    margin-top: 8px;
    width: 100%;
    background-color: white;
    border-radius: 0.25rem;
    overflow: auto;
  }
  .vue2-autocomplete-input-tag-item {
    padding: 6px 16px;
    color: #4a4a4a;
    max-width: 100%;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
  }
  .vue2-autocomplete-input-tag-item:hover {
    background-color: #e8e8e8;
  }
</style>
<script>
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');
import axios from 'axios';
import Editor from '@tinymce/tinymce-vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VoerroTagsInput from '@voerro/vue-tagsinput';
require('@voerro/vue-tagsinput/dist/style.css');
import { VueEditor } from "vue2-editor";





// import { CurrencyInput } from 'vue-currency-input'

let appName = null;

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    DatePicker,
    'editor': Editor,
    "tags-input": VoerroTagsInput,
    VueEditor
  },
  data () {
    return {
      app : null,
      loadingUpload : false,
      base_url: process.env.VUE_APP_BASE_URL,
      isSchedule: '',
      new_image: '',
      new_image_file: '',
      new_image_src: require('../../assets/images/no-pictures.png'),
      new_image_caption: '',
      filelist: [],
      search: "",
      galeriModal: false,
      apiKey: process.env.VUE_APP_TINY_API_KEY,
      myValue: "",
      nama_upt : "",
      title : "",
      details: "",
      slider: 1,
      image_link: "",
      caption_image: "",
      author: "",
      source2: "",
      deskripsi: "",
      tgl_berita : this.$date().valueOf(),
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      source : [],
      dasar_surat_tugas : "",
      category : "",
      jenis_tujuan : "",
      pegawais : [],
      categories : [],
      tags : [],
      tags2 : [],
      selected: [], // Must be an array reference!
      upts: [],
      images: [],
      show: true,
      customToolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['image', 'code-block']
      ],
      onEditorFocus: null,
      editorSettings2: {
        modules: {
               toolbar: {
                  container: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['image', 'code-block']
                  ],
                  handlers: {
                     image: this.onImageClick
                  }
             }
        },
      },
      editorSettings: {
        setup: function (editor) {
          console.log('setup', editor);

          // Register the custom button directly within the setup method
          editor.ui.registry.addButton('test', {
            icon: 'image',
            tooltip: 'Add Custom Action',
            onAction: function () {
              console.log('test button clicked');
              appName.imageSourceClick = 'tiny';
              appName.galeriModal = true;
            },
          });

          editor.on('init', function () {
            console.log('setup init', editor);
          });
        },
        height: 500,
        menubar: false,
        contextmenu: false,
        plugins: [
          'advlist  lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'test | undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify bullist numlist | \
            outdent indent | removeformat | '
      },
      maxLengthInCars: 140,
      imageSourceClick :'tiny',
      showDate : false,
      schedule: 0,
      submitString: "Submit",
      isSubmitable : true,
      imageOrientation : ''
    }
  },
  created(){
    appName = this;
  },
  // watch: {
  //   source: function(oldVal, newVal) {
  //     console.log(oldVal + " " + newVal);
  //     // console.log(this.tags)
  //   }
  // },
  mounted(){
    this.loadData();
    let us = JSON.parse(this.$store.state.users);
    this.author=us.name;
    this.$refs.content.quill.selection.savedRange.index
  },
  
  methods: {
    onImageClick(){
      console.log('test button clicked');
      this.imageSourceClick = 'tiny';
      this.galeriModal = true;
    },
    optionSelected(option, id) {
      console.log("option",option)
    },
    // addTag(newTag) {
    //   // const tag = {
    //   //   title: newTag,
    //   //   // you'll need to add other items specific to your objects        
    //   // };

    //   const tag = { 
    //                       tag:newTag, 
    //                       };
    //   // alert(JSON.stringify(tags));
    //   axios.post(process.env.VUE_APP_BASE_URL+"tags/insert", tag)
    //     .then(() => {
    //         // console.log(response);
    //         // alert(response.data);
    //         // if(response.status==200){
    //         //   this.$router.push('/rubrik');
    //         // }
    //         // else{
    //         //   this.$root.$emit("bv::show::modal", "modalError");
    //         // }     
            
    //         this.tags.push(newTag);       
    //     })
    //     // this.options.find(option => option.id === this.id);

    //   // this.source = this.source.length> 0 ? this.source +", "+newTag : newTag ;
    //   this.source = this.tagss(i=>i.text == newTag)
    // },
    checkSchedule : function(value, event) {
        if(event.target.checked) {
          console.log('The checkbox is clicked')
          this.showDate = true
          this.schedule = 1
        }   
        else{
          this.showDate = false
          this.schedule = 0
        }
    },
    setTinyContentNew: function() {
      if(this.new_image){
        const caption_img = this.$refs.new_image_caption.innerHTML;
       if(this.imageSourceClick==='tiny'){
          this.galeriModal = false;
          const string = "<br><img src='"+this.new_image+"' style='width:100%' /><p>"+caption_img+"</p>";
          tinymce.activeEditor.insertContent(string);
          console.log(string);
        }
        else{
          this.galeriModal = false;
          this.caption_image = caption_img;
          this.image_link = this.new_image
        }
      }
      console.log(this.imageSourceClick)
      
    },
    setTinyContent: function(content) {
      if(this.imageSourceClick==='tiny'){

        this.galeriModal = false;
        const string = "<br><img src='"+content.image+"' style='width:100%' /><p>"+content.image_caption+"</p>";
        tinymce.activeEditor.insertContent(string);
        console.log(content);
      }
      else{

        this.galeriModal = false;
        this.caption_image = content.image_caption;
          this.image_link = content.image
      }
      console.log(this.imageSourceClick)
    },
    handleEditorInit() {
      console.log('Editor initialized');
    },
    processJSON(json) {
        return json;
    },
    getData(obj){
        this.totals += +obj.product_price * +this.qty;
        this.barangs.push(obj);
        this.$refs.autocomplete.setValue('');
        console.log(obj);
    },  
    getData2(obj){
        console.log(obj);
    },  
    loadTags(selectedItems){
      if(selectedItems.length>2)
      { 
        axios.get(process.env.VUE_APP_BASE_URL+"tags/search?query="+selectedItems)
        .then((response) => {
              response.data.forEach(obj=> this.tags.push(obj.text));
            });
      }

    },
    setVal () {
      // 设置初始值
      this.$refs.editer.run('code', '这里是富文本的初始值')
    },
    getVal () {
      // 获取初始值
      this.$refs.editer.run('code')
    },
    initLoadImage(){
      this.search = null;
      this.searchImage();
    },
    searchImage(){
      console.log(this.search.length)
      if(this.search.length>2){
        axios.get(process.env.VUE_APP_BASE_URL+"images?search="+this.search)
          .then((response) => {
           this.images = [];
           this.images = response.data;
        });
      }
      else{
        axios.get(process.env.VUE_APP_BASE_URL+"images")
          .then((response) => {
            this.images = response.data;
        });
      }
    },
    loadData: function(){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"categories")
        .then((response) => {
              this.categories = response.data;
            });

      axios.get(process.env.VUE_APP_BASE_URL+"tags")
        .then((response) => {
              response.data.forEach(obj=> this.tags.push(obj.text));
            });

    axios.get(process.env.VUE_APP_BASE_URL+"tags2")
      .then((response) => {
            // response.data.forEach(obj=> this.tags2.push(obj.value));
            this.tags2 = response.data
            console.log(this.tags2)
          });
      
      this.searchImage();
      

    },
    assertMaxChars: function () {
        if (this.value.length >= this.maxLengthInCars) {
            this.value = this.value.substring(0,this.maxLengthInCars);
        }
    },
    inputNews: function(){
      this.submitString = 'Loading...'
      this.isSubmitable = false;
      const sourceArray = [];
      this.source.forEach(obj=> sourceArray.push(obj.value));
      const newSource2 = sourceArray.join(',')
      const newdatetime = this.$date(this.tgl_berita).format('YYYY-MM-DD HH:mm:ss') ;

      const formData = new FormData();

        // formData.append('id_upt', this.id_upt)
        formData.append('title', this.title)
        if(this.tgl_berita){
          formData.append('tgl_berita', newdatetime)  
        }
        formData.append('details', this.details)
        formData.append('image_link', this.image_link)
        formData.append('image_caption', this.caption_image)
        formData.append('deskripsi', this.deskripsi)
        formData.append('source', newSource2)
        formData.append('author', this.author)
        formData.append('slider', this.slider)
        formData.append('category_id', this.category)
        formData.append('schedule', this.schedule)
      
      // alert(JSON.stringify(formData));
      axios.post(process.env.VUE_APP_BASE_URL+"news/insert", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){

              this.new_image = "";
              this.$router.push('/news');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    onChange(event) {
      // this.filelist = [...this.$refs.file.files];

      var fileData = event.target.files[0];
      // this.file_bukti_pembayaran_taxi_text = fileData.name;
      this.new_image_file = fileData;
      // var _URL = window.URL || window.webkitURL;
      // let file;
      // if ((file = fileData)) {
      //     let img = new Image();
      //     const objectUrl = _URL.createObjectURL(file);
      //     const newThis = this;
      //     img.onload = function () {
      //         console.log(this.width + " " + this.height);
      //         if(this.width>this.height) newThis.imageOrientation ='l'
      //         else newThis.imageOrientation = 'p'
      //         _URL.revokeObjectURL(objectUrl);
      //     };
      //     img.src = objectUrl;
      

      // }
              console.log("orientation", this.imageOrientation)
              this.imgUpload();
      // this.placeholder10 = fileData.name;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    imgUpload: function(){
      this.loadingUpload = true;
      let endpointUpload;
      if(this.imageOrientation=='l') endpointUpload = "news/upload-image"
      else endpointUpload = "news/upload-image-portrait";
      console.log('l',this.imageOrientation)
      const formData = new FormData();   
      if(this.new_image_file.name){
        formData.append('image', this.new_image_file, this.new_image_file.name) 
      }
      console.log("endpoint",endpointUpload)
      axios.post(process.env.VUE_APP_BASE_URL+"news/upload-image", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            this.loadingUpload = false;
            if(response.status==200){
              this.new_image_src = response.data.image
              this.new_image = response.data.image
            }
            else{
              this.modalError = true;
            }            
        })
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>